










import { Component, Vue } from 'vue-property-decorator';
import LayoutModule from '@/store/modules/LayoutModule';
import leftsidebar from '@/pages/explorer/components/leftsidebar/LeftSidebar.vue';

import leftsidebarheader from '@/pages/explorer/components/leftsidebar/LeftSidebarHeader.vue';
import explorercontenttitlebar from '@/pages/explorer/components/ExplorerContentTitlebar.vue';
import explorerrecords from '@/pages/explorer/components/ExplorerRecords.vue';
import ExplorerUiStateModule from '@/store/modules/ExplorerUiStateModule';
import ExportUiStateModule from '@/store/modules/ExportUiStateModule';
import { ISelectFolder } from '@/store/types/ISelectFolder';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate', // for vue-router 2.2+
]);

@Component({
  components: {
    leftsidebar,
    leftsidebarheader,
    explorerrecords,
    //exportwizard,
    explorercontenttitlebar,
  },
})
export default class ExportPage extends Vue {
  get leftsidebaropen() {
    return LayoutModule.leftsidebaropen;
  }

  get selectedfolder() {
    return ExplorerUiStateModule.selectedFolder;
  }

  //todo should these go in the router??
  beforeRouteEnter(to: any, from: any, next: any) {
    ExportUiStateModule.clearSelectedFolder();
    ExplorerUiStateModule.selectFolder({
      folderName: to.params.folder,
      selectedId: to.params.selecteditemid,
    } as ISelectFolder);
    next();
  }

  //todo should these go in the router??
  beforeRouteUpdate(to: any, from: any, next: any) {
    // Don't clear the Export Ui State Below
    // ExportUiStateModule.clear();
    ExplorerUiStateModule.selectFolder({
      folderName: to.params.folder,
      selectedId: to.params.selecteditemid,
    } as ISelectFolder);
    next();
  }
}
