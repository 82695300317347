








import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class LeftSidebarHeader extends Vue {
  @Prop() title!: string;
}
